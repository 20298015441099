<template>
  <div>
    <div class="h-text-center">
      <div class="h-m-t-10">
        <van-image :src="require('@/assets/images/pay_success.png')" style="height:120px;width:120px" />
      </div>
      <div class="h-font-lg h-font-bold-sm h-m-t-10">
        <span>支付成功！</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageData: {
        interval: null
      },
      orderNo: "",
      orderId: null
    };
  },
  created() {
    const { orderNo, orderId } = this.$route.query;
    this.orderNo = orderNo;
    this.orderId = orderId;
  }
};
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 30px;
  padding: 0 10px;
}
</style>
